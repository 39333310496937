import React from 'react'

const Error = (props) => {
  return (
    <>
    <div id="berhasilInputForm" className="pages mb-3 mt-3">
      <h1 className="textCenter">Oops !</h1>
      <h3 className="textCenter">Ada Kesalahan, harap ulangi atau laporkan pada panitia</h3>
      <div className="d-flex justify-content-center">
        <button id="buttonSubmitLagi" className="btn btn-primary" onClick={() => props.update("input")}><i class='bx bx-arrow-back'></i> Input Lagi</button>
      </div>
    </div>
  </>
  )
}

export default Error